.categories {
  margin: 50px 20px 0 -100px;
  width: 80px;
  display: flex;
  flex-direction: column;
}

.category_tag {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  white-space: nowrap;
}

.skills_display {
  display: flex;
  flex: 1;
  width: 105%;
  flex-flow: row wrap;
  margin-bottom: 20px;
  /*  
  inset neumorphism styling
  border-radius: 20px;
  background: #f7f2ee;
  box-shadow: inset 6px 6px 12px #dedad6, inset -6px -6px 12px #ffffff; 
  */
}

.subheading {
  font-weight: 500;
  font-size: 1.25em;
  color: #666666;
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* Extra Small Devices, Phones */

  .skills_display {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* Extra Small Devices, Phones */

  .skills_display {
    margin-right: 30px;
  }
}
