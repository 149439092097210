.heading_section {
  display: flex;
}

.heading {
  font-size: 13rem;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

.info {
  padding-top: 150px;
  padding-left: 50px;
}

.intro_message_top {
  padding-bottom: 1rem;
}

.introduction {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 992px) {
  /* Medium Devices, Desktops */

  .heading_section {
    display: block;
    margin-top: 50px;
  }

  .heading {
    width: 100%;
    font-size: 11rem;
    line-height: 150px;
  }

  .info {
    padding-top: 0;
    padding-left: 0;
  }

  .introduction {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  /* Medium Devices, Desktops */

  .heading {
    width: 90vw;
    font-size: 8rem;
    line-height: 150px;
  }

  .heading_section {
    display: block;
    margin-top: 0rem;
  }

  .intro_message {
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  /* Medium Devices, Desktops */

  .heading {
    width: 90vw;
    font-size: 6.5rem;
    line-height: 150px;
  }

  .intro_message {
    padding: 0;
  }

  .introduction {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 360px) {
  /* Medium Devices, Desktops */

  .heading {
    width: 100%;
    font-size: 5.5rem;
    line-height: 150px;
  }
}

@media only screen and (max-width: 320px) {
  /* Custom, iPhone Retina */

  .heading {
    width: 100%;
    font-size: 4.5rem;
    line-height: 100px;
  }
}
