body {
  margin: 0;
  font-family: halcom, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  overflow: visible;
  -webkit-transition: background-color 500ms ease;
  -moz-transition: background-color 500ms ease;
  -ms-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

body.dark-mode {
  background-color: #2c2e3f;
}

body.light-mode {
  background-color: #e7f0fa;
}
