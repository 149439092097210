@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

h1 {
  font-size: 8rem;
  font-weight: 400;
  margin-block-start: 0.33em;
  margin-block-end: 0.33em;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

h2 {
  font-size: 6rem;
  font-weight: 400;
  margin-block-start: 0.33em;
  margin-block-end: 0.33em;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

li {
  font-size: 1.25em;
  font-weight: 500;
  margin-block-start: 0;
  margin-block-end: 0.25em;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 1.5em;
  font-weight: 500;
  padding-right: 8vw;
  margin-block-start: 0;
  margin-block-end: 1em;
  font-family: "Poppins", sans-serif;
  color: #2c2e3f;
}

p strong {
  font-weight: 700;
  color: #2c2e3f;
}

.zooja_elements {
  font-family: "zooja-elements-and-borders", sans-serif;
  font-weight: 400;
  font-style: normal;
}

p .zooja_elements {
  font-size: 1.5em;
}

.heading_box {
  display: flex;
  font-size: 6rem;
}

.side_icon {
  margin-right: 20px;
}

.blank_space {
  height: 15vh;
}

.page {
  position: relative;
  padding-top: 6vh;
  height: 100%;
  overflow-x: hidden;
  background-color: #e7f0fa;
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.page_dark {
  position: relative;
  padding-top: 6vh;
  height: 100%;
  overflow-x: hidden;
  background-color: #2c2e3f;
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.first_page_block {
  position: absolute;
  z-index: 100;
  height: 80vh;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.container p {
  padding-right: 0;
}

.page_block {
  min-height: 75vh;
  padding: 8vh 16vw;
  overflow: visible;
  outline: none;
}

.info_btn {
  margin-right: 5px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 1200px) {
  /* Medium Devices, Desktops */

  .page_block {
    padding: 5vh 10vw;
    outline: none;
  }
}

@media only screen and (max-width: 992px) {
  .blank_space {
    height: 10vh;
  }

  .page {
    padding-top: 10vh;
  }

  .page_dark {
    padding-top: 10vh;
  }
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */
  .blank_space {
    height: 0;
  }

  .container {
    display: block;
    margin-right: 0;
  }

  h2 {
    line-height: 70px;
  }

  .page {
    padding-top: 20vh;
  }

  .page_dark {
    padding-top: 20vh;
  }
}

@media only screen and (max-width: 576px) {
  p {
    font-size: 1.25em;
    font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 1em;
  }

  p strong {
    font-size: 1.4em;
    font-weight: 600;
    margin-block-start: 0;
    margin-block-end: 1em;
  }

  li {
    font-size: 1.2em;
    font-weight: 500;
    margin-block-start: 0;
  }

  .heading_box {
    font-size: 5rem;
  }
}
