.projects_subcontainer {
  width: 100%;
}

.projects_image {
  width: 100%;
  border-radius: 15px;
  object-fit: contain;
  margin-bottom: 20px;
}

.skills_display {
  display: flex;
  flex: 1;
  width: 105%;
  flex-flow: row wrap;

  /*  
  inset neumorphism styling
  border-radius: 20px;
  background: #f7f2ee;
  box-shadow: inset 6px 6px 12px #dedad6, inset -6px -6px 12px #ffffff; 
  */
}

.subheading {
  font-weight: 500;
  font-size: 1.25em;
  color: #000;
  width: 100%;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.1rem;
  font-weight: 500;
  padding-right: 0px;
}

.contributions {
  color: #2c2e3f;
  font-size: 0.95rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  /* Extra Small Devices, Phones */

  .skills_display {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* Extra Small Devices, Phones */

  .skills_display {
    margin-right: 30px;
  }
}

@media (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .skills_display {
    width: 100%;
  }

  .subheading {
    margin-bottom: 0.5rem;
  }
}
