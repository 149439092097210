/* Navigation Bar {utils/components/navbar.tsx} */
header {
  background-color: #e7f0fa;
}

.navbar {
  position: absolute;
  background-color: #e7f0fa;
  height: 2rem;
  display: flex;
  width: calc(100% - 32vw);
  padding: 5vh 16vw;
  z-index: 10;
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.navbar a {
  text-decoration: none;
}

.navbar_open {
  position: relative;
  top: 6rem;
  background-color: #e7f0fa;
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 20vw);
  padding: 2vh 10vw;
  z-index: 10;
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.homelink {
  font-size: 2.5rem;
  font-weight: 400;
  text-decoration: none;
  margin-left: 10px;
  vertical-align: text-bottom;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

.links {
  padding-top: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.drawer {
  position: absolute;
  top: 1px;
}

.menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.menu_button,
menuButton:focus {
  outline: none;
  background: transparent;
  border: none;
  margin-top: 0.25rem;
  cursor: pointer;
}

.menu_button:active {
  outline: none;
  background: transparent;
  border: none;
  margin-top: 0.25rem;
  cursor: pointer;
}

.link {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #2c2e3f;

  display: inline-block;
  position: relative;
}

.link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #2c2e3f;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.link:hover,
.link:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.link_dark {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #e7f0fa;

  display: inline-block;
  position: relative;
}

.link_dark:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #e7f0fa;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link_dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.link_dark:hover,
.link_dark:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.menu_toggle {
  margin: 0 20px;
  display: flexbox;
  position: relative;
}

.menu_link {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #2c2e3f;

  display: flexbox;
  position: relative;
}

.menu_link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #2c2e3f;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu_link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu_link:hover,
.menu_link:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.menu_link_dark {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #e7f0fa;

  display: flexbox;
  position: relative;
}

.menu_link_dark:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #e7f0fa;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu_link_dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu_link_dark:hover,
.menu_link_dark:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.menu_list {
  list-style-type: none;
  padding: 0;
}

.menu_item {
  margin-bottom: 1.5rem;
}

/* Logo Icons {utils/components/icon.tsx} */
.logo {
  position: relative;
  height: 85px;
  width: 60px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.logo_image {
  position: absolute;
  z-index: 1;
  margin-top: 6px;
  height: 48px;
  object-fit: contain;
}

.logo_box {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 20px;
  background: #e7f0fa;
  box-shadow: 6px 6px 7px #d9e2eb, -6px -6px 7px #f9fcfd;
}

.logo_box_dark {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 20px;
  background: #2c2e3f;
  box-shadow: 6px 6px 7px #191e2b, -6px -6px 7px #353c50;
}

.logo_name {
  transition: color 0.3s;
  color: transparent;
  font-size: 0.85rem;
  font-weight: 500;
  align-self: flex-end;
  white-space: nowrap;
  padding-bottom: 2px;
  z-index: 2;
}

.logo:hover .logo_name {
  color: #2c2e3f;
}

.logo_name_dark {
  transition: color 0.3s;
  color: transparent;
  font-size: 0.85rem;
  font-weight: 500;
  align-self: flex-end;
  white-space: nowrap;
  padding-bottom: 2px;
  z-index: 2;
}

.logo:hover .logo_name_dark {
  color: #e7f0fa;
}

/* 
* Chevron Up Button 
* for scrolling to the top of page {components/upbutton.tsx} 
*/
.chevron_up_button {
  position: fixed;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 1000;
  height: 64px;
  bottom: 24px;
  right: 30px;
  cursor: pointer;
}

.bounce:hover {
  animation-name: bounce-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes bounce-animation {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-7px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-3px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* SideNav {utils/components/sidenav.tsx} */
.sidenav {
  position: fixed;
  padding: 50% 0;
}

/* Footer {utils/components/footer.tsx} */
.footer {
  position: relative;
  height: 20vh;
  outline: none;
  overflow: hidden;
}

.footer_background {
  position: absolute;
  width: 100%;
  height: 175px;
  background-color: #e7f0fa;
  bottom: 0;
}

.footer_background_dark {
  position: absolute;
  width: 100%;
  height: 175px;
  background-color: #2c2e3f;
  bottom: 0;
}

.footer_image {
  position: absolute;
  width: 100%;
  bottom: 160px;
  object-fit: cover;
}

.footer_content {
  position: absolute;
  display: flex;
  height: 60px;
  width: calc(100% - 200px);
  z-index: 100;
  margin: 0 100px;
  bottom: 60px;
}

.footer_content_left {
  flex: 1;
  display: flex;
}

.footer_content_center {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.footer_content_right {
  flex: 1;
}

.footer_heading {
  color: #2c2e3f;
  font-size: 3rem;
  font-weight: 400;
  font-family: "zooja-pro";
}

.footer_text {
  color: #2c2e3f;
  font-size: 1.5rem;
  font-weight: 500;
}

.buttons {
  margin-top: 10px;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  /* Medium Devices, Desktops */
  .navbar {
    width: calc(100% - 20vw);
    padding: 5vh 10vw;
  }

  .footer {
    height: 20vh;
  }

  .footer_content {
    bottom: 60px;
  }

  .menu {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */
  .links {
    display: none;
  }

  .link {
    margin: 5px 20px;
  }

  .footer {
    height: 35vh !important;
  }

  .footer_content {
    display: block;
    height: unset;
    width: calc(100% - 100px);
    margin: 0 50px;
    bottom: 40px !important;
  }

  .buttons {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .footer {
    height: 40vh !important;
  }

  .logo {
    height: 45px;
    width: 36px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .logo_image {
    position: absolute;
    z-index: 1;
    margin-top: 6px;
    height: 36px;
    object-fit: contain;
  }

  .logo_name {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: -0.85rem;
    z-index: 2;
  }

  .logo_name_dark {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: -0.85rem;
    z-index: 2;
  }
}

@media only screen and (max-width: 360px) {
  /* Extra Small Devices, Phones */
  .footer {
    height: 45vh !important;
  }
}

@media only screen and (max-width: 320px) {
  /* Extra Small Devices, Phones */
  .footer {
    height: 50vh !important;
  }
}
