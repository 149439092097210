.form_container {
  width: calc(100% - 20px);
  padding: 50px 40px;
  margin-left: -30px;
  margin-bottom: 75px;
  border-radius: 20px;
  background: #e7f0fa;

  box-shadow: 10px 10px 20px #d9e2eb, -10px -10px 20px #f9fcfd;
}

.form_container_dark {
  width: calc(100% - 20px);
  padding: 50px 40px;
  margin-left: -30px;
  margin-bottom: 75px;
  border-radius: 20px;
  background: #2c2e3f;

  box-shadow: 10px 10px 20px #191e2b, -10px -10px 20px #353c50;
}

.form_row {
  width: 100%;
  display: flex;
  margin-left: 15px;
}

.form_error_row {
  width: 100%;
  display: flex;
  margin-left: 30px;
  margin-bottom: 25px;
}

.form_error {
  flex: 1;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 500;
  color: #9d4345;
}

.form_input {
  flex: 1;
  height: 55px;
  margin-right: 30px;
  padding: 0 20px;

  font-family: "halcom", sans-serif;
  font-size: 1em;
  color: #000;

  border: none;
  border-radius: 10px;
  background: #e7f0fa;
  -webkit-appearance: none;
  box-shadow: inset 6px 6px 7px #d9e2eb, inset -6px -6px 7px #f9fcfd;
}

textarea.form_input {
  height: 150px !important;
  resize: none;
  padding: 15px 20px;
}

.form_input::placeholder {
  outline: none;
  color: #666666;
}

.form_input:focus {
  outline: none;
  background: #e7f0fa;
  -webkit-appearance: none;
  box-shadow: inset 2px 2px 4px #d9e2eb, inset -2px -2px 4px #f9fcfd;
}

.form_input:focus::placeholder {
  color: #000;
}

.form_input_dark {
  flex: 1;
  height: 55px;
  margin-right: 30px;
  padding: 0 20px;

  font-family: "halcom", sans-serif;
  font-size: 1em;
  color: #000;

  border: none;
  border-radius: 10px;
  background: #2c2e3f;
  -webkit-appearance: none;
  box-shadow: inset 6px 6px 7px #191e2b, inset -6px -6px 7px #353c50;
}

textarea.form_input_dark {
  height: 150px !important;
  resize: none;
  padding: 15px 20px;
}

.form_input_dark::placeholder {
  outline: none;
  color: #666666;
}

.form_input_dark:focus {
  outline: none;
  background: #2c2e3f;
  -webkit-appearance: none;
  box-shadow: inset 2px 2px 4px #191e2b, inset -2px -2px 4px #353c50;
}

.form_input_dark:focus::placeholder {
  color: #fff;
}

.form_button_row {
  width: 100%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.submit_btn {
  height: 50px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #fff;

  border: none;
  border-radius: 50px;
  background: #2d2f44;
  box-shadow: 6px 6px 7px #d9e2eb, -6px -6px 7px #f9fcfd;
}

.submit_btn_dark {
  height: 50px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #000;

  border: none;
  border-radius: 50px;
  background: #e7f0fa;
  box-shadow: 6px 6px 7px #191e2b, -6px -6px 7px #353c50;
}

.submit_btn_loading {
  height: 30px;
  margin-top: 3px;
  object-fit: contain;
}

.toast_success {
  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #fff;

  padding-left: 15px;

  border-radius: 10px;
  background: #9d4345;
  box-shadow: 6px 6px 7px #dedad6, -6px -6px 7px #ffffff;
}

.toast_success button {
  color: #fff;
  opacity: 1;
  padding-top: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */

  .form_container {
    width: calc(100% - 40px);
    padding: 30px 20px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  .form_container_dark {
    width: calc(100% - 40px);
    padding: 30px 20px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  .form_row {
    display: flex;
  }
}

@media only screen and (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .form_container {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 50px;
    box-shadow: none;
  }

  .form_container_dark {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 50px;
    box-shadow: none;
  }

  .form_row {
    width: 100%;
    display: flex;
    margin-left: 0;
  }

  .form_input {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-right: 0;
  }

  .form_input_dark {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-right: 0;
  }
}
