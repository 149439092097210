@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: halcom, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  overflow: visible;
  transition: background-color 500ms ease;
}

body.dark-mode {
  background-color: #2c2e3f;
}

body.light-mode {
  background-color: #e7f0fa;
}

h1 {
  font-size: 8rem;
  font-weight: 400;
  -webkit-margin-before: 0.33em;
          margin-block-start: 0.33em;
  -webkit-margin-after: 0.33em;
          margin-block-end: 0.33em;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

h2 {
  font-size: 6rem;
  font-weight: 400;
  -webkit-margin-before: 0.33em;
          margin-block-start: 0.33em;
  -webkit-margin-after: 0.33em;
          margin-block-end: 0.33em;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

li {
  font-size: 1.25em;
  font-weight: 500;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 1.5em;
  font-weight: 500;
  padding-right: 8vw;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  font-family: "Poppins", sans-serif;
  color: #2c2e3f;
}

p strong {
  font-weight: 700;
  color: #2c2e3f;
}

.app_zooja_elements__8fOLS {
  font-family: "zooja-elements-and-borders", sans-serif;
  font-weight: 400;
  font-style: normal;
}

p .app_zooja_elements__8fOLS {
  font-size: 1.5em;
}

.app_heading_box__1pKSd {
  display: flex;
  font-size: 6rem;
}

.app_side_icon__8tk8J {
  margin-right: 20px;
}

.app_blank_space__1jNB6 {
  height: 15vh;
}

.app_page__2eoNV {
  position: relative;
  padding-top: 6vh;
  height: 100%;
  overflow-x: hidden;
  background-color: #e7f0fa;
  transition: background-color 300ms ease;
}

.app_page_dark__3-SiG {
  position: relative;
  padding-top: 6vh;
  height: 100%;
  overflow-x: hidden;
  background-color: #2c2e3f;
  transition: background-color 300ms ease;
}

.app_first_page_block__Rg2GH {
  position: absolute;
  z-index: 100;
  height: 80vh;
}

.app_container__2JKVN {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.app_container__2JKVN p {
  padding-right: 0;
}

.app_page_block__3boOY {
  min-height: 75vh;
  padding: 8vh 16vw;
  overflow: visible;
  outline: none;
}

.app_info_btn__2U_rS {
  margin-right: 5px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 1200px) {
  /* Medium Devices, Desktops */

  .app_page_block__3boOY {
    padding: 5vh 10vw;
    outline: none;
  }
}

@media only screen and (max-width: 992px) {
  .app_blank_space__1jNB6 {
    height: 10vh;
  }

  .app_page__2eoNV {
    padding-top: 10vh;
  }

  .app_page_dark__3-SiG {
    padding-top: 10vh;
  }
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */
  .app_blank_space__1jNB6 {
    height: 0;
  }

  .app_container__2JKVN {
    display: block;
    margin-right: 0;
  }

  h2 {
    line-height: 70px;
  }

  .app_page__2eoNV {
    padding-top: 20vh;
  }

  .app_page_dark__3-SiG {
    padding-top: 20vh;
  }
}

@media only screen and (max-width: 576px) {
  p {
    font-size: 1.25em;
    font-weight: 500;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
  }

  p strong {
    font-size: 1.4em;
    font-weight: 600;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
  }

  li {
    font-size: 1.2em;
    font-weight: 500;
    -webkit-margin-before: 0;
            margin-block-start: 0;
  }

  .app_heading_box__1pKSd {
    font-size: 5rem;
  }
}

.homepage_heading_section__2br5x {
  display: flex;
}

.homepage_heading__2LM-p {
  font-size: 13rem;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

.homepage_info__3VZc5 {
  padding-top: 150px;
  padding-left: 50px;
}

.homepage_intro_message_top__2AImf {
  padding-bottom: 1rem;
}

.homepage_introduction__10gTd {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 992px) {
  /* Medium Devices, Desktops */

  .homepage_heading_section__2br5x {
    display: block;
    margin-top: 50px;
  }

  .homepage_heading__2LM-p {
    width: 100%;
    font-size: 11rem;
    line-height: 150px;
  }

  .homepage_info__3VZc5 {
    padding-top: 0;
    padding-left: 0;
  }

  .homepage_introduction__10gTd {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  /* Medium Devices, Desktops */

  .homepage_heading__2LM-p {
    width: 90vw;
    font-size: 8rem;
    line-height: 150px;
  }

  .homepage_heading_section__2br5x {
    display: block;
    margin-top: 0rem;
  }

  .homepage_intro_message__2XwQK {
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  /* Medium Devices, Desktops */

  .homepage_heading__2LM-p {
    width: 90vw;
    font-size: 6.5rem;
    line-height: 150px;
  }

  .homepage_intro_message__2XwQK {
    padding: 0;
  }

  .homepage_introduction__10gTd {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 360px) {
  /* Medium Devices, Desktops */

  .homepage_heading__2LM-p {
    width: 100%;
    font-size: 5.5rem;
    line-height: 150px;
  }
}

@media only screen and (max-width: 320px) {
  /* Custom, iPhone Retina */

  .homepage_heading__2LM-p {
    width: 100%;
    font-size: 4.5rem;
    line-height: 100px;
  }
}

/* Navigation Bar {utils/components/navbar.tsx} */
header {
  background-color: #e7f0fa;
}

.components_navbar__3eS16 {
  position: absolute;
  background-color: #e7f0fa;
  height: 2rem;
  display: flex;
  width: calc(100% - 32vw);
  padding: 5vh 16vw;
  z-index: 10;
  transition: background-color 300ms ease;
}

.components_navbar__3eS16 a {
  text-decoration: none;
}

.components_navbar_open__1o-CB {
  position: relative;
  top: 6rem;
  background-color: #e7f0fa;
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 20vw);
  padding: 2vh 10vw;
  z-index: 10;
  transition: background-color 300ms ease;
}

.components_homelink__33fYU {
  font-size: 2.5rem;
  font-weight: 400;
  text-decoration: none;
  margin-left: 10px;
  vertical-align: text-bottom;
  font-family: "zooja-pro";
  color: #2c2e3f;
}

.components_links__2un9O {
  padding-top: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.components_drawer__2GIPK {
  position: absolute;
  top: 1px;
}

.components_menu__21-6z {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.components_menu_button__3pqen,
menuButton:focus {
  outline: none;
  background: transparent;
  border: none;
  margin-top: 0.25rem;
  cursor: pointer;
}

.components_menu_button__3pqen:active {
  outline: none;
  background: transparent;
  border: none;
  margin-top: 0.25rem;
  cursor: pointer;
}

.components_link__3llFs {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #2c2e3f;

  display: inline-block;
  position: relative;
}

.components_link__3llFs:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #2c2e3f;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.components_link__3llFs:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.components_link__3llFs:hover,
.components_link__3llFs:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.components_link_dark__1-LnA {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #e7f0fa;

  display: inline-block;
  position: relative;
}

.components_link_dark__1-LnA:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #e7f0fa;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.components_link_dark__1-LnA:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.components_link_dark__1-LnA:hover,
.components_link_dark__1-LnA:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.components_menu_toggle__GZ3re {
  margin: 0 20px;
  display: flexbox;
  position: relative;
}

.components_menu_link__23Mnz {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #2c2e3f;

  display: flexbox;
  position: relative;
}

.components_menu_link__23Mnz:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #2c2e3f;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.components_menu_link__23Mnz:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.components_menu_link__23Mnz:hover,
.components_menu_link__23Mnz:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.components_menu_link_dark__1UV_p {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 20px;
  color: #e7f0fa;

  display: flexbox;
  position: relative;
}

.components_menu_link_dark__1UV_p:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #e7f0fa;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.components_menu_link_dark__1UV_p:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.components_menu_link_dark__1UV_p:hover,
.components_menu_link_dark__1UV_p:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.components_menu_list__3CYF8 {
  list-style-type: none;
  padding: 0;
}

.components_menu_item__1BD-4 {
  margin-bottom: 1.5rem;
}

/* Logo Icons {utils/components/icon.tsx} */
.components_logo__2juTe {
  position: relative;
  height: 85px;
  width: 60px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.components_logo_image__kv4IR {
  position: absolute;
  z-index: 1;
  margin-top: 6px;
  height: 48px;
  object-fit: contain;
}

.components_logo_box__2huQp {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 20px;
  background: #e7f0fa;
  box-shadow: 6px 6px 7px #d9e2eb, -6px -6px 7px #f9fcfd;
}

.components_logo_box_dark__1P_Ny {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 20px;
  background: #2c2e3f;
  box-shadow: 6px 6px 7px #191e2b, -6px -6px 7px #353c50;
}

.components_logo_name__1UZTH {
  transition: color 0.3s;
  color: transparent;
  font-size: 0.85rem;
  font-weight: 500;
  align-self: flex-end;
  white-space: nowrap;
  padding-bottom: 2px;
  z-index: 2;
}

.components_logo__2juTe:hover .components_logo_name__1UZTH {
  color: #2c2e3f;
}

.components_logo_name_dark__3r2gg {
  transition: color 0.3s;
  color: transparent;
  font-size: 0.85rem;
  font-weight: 500;
  align-self: flex-end;
  white-space: nowrap;
  padding-bottom: 2px;
  z-index: 2;
}

.components_logo__2juTe:hover .components_logo_name_dark__3r2gg {
  color: #e7f0fa;
}

/* 
* Chevron Up Button 
* for scrolling to the top of page {components/upbutton.tsx} 
*/
.components_chevron_up_button__2K4jw {
  position: fixed;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 1000;
  height: 64px;
  bottom: 24px;
  right: 30px;
  cursor: pointer;
}

.components_bounce__2CxQ-:hover {
  -webkit-animation-name: components_bounce-animation__16n7M;
          animation-name: components_bounce-animation__16n7M;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

@-webkit-keyframes components_bounce-animation__16n7M {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-7px);
            transform: scale(0.9, 1.1) translateY(-7px);
  }
  50% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(-3px);
            transform: scale(1, 1) translateY(-3px);
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}

@keyframes components_bounce-animation__16n7M {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-7px);
            transform: scale(0.9, 1.1) translateY(-7px);
  }
  50% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(-3px);
            transform: scale(1, 1) translateY(-3px);
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}

/* SideNav {utils/components/sidenav.tsx} */
.components_sidenav__F32gG {
  position: fixed;
  padding: 50% 0;
}

/* Footer {utils/components/footer.tsx} */
.components_footer__3fCJE {
  position: relative;
  height: 20vh;
  outline: none;
  overflow: hidden;
}

.components_footer_background__2BWOx {
  position: absolute;
  width: 100%;
  height: 175px;
  background-color: #e7f0fa;
  bottom: 0;
}

.components_footer_background_dark__54Xe2 {
  position: absolute;
  width: 100%;
  height: 175px;
  background-color: #2c2e3f;
  bottom: 0;
}

.components_footer_image__fWwbs {
  position: absolute;
  width: 100%;
  bottom: 160px;
  object-fit: cover;
}

.components_footer_content__Gnz0x {
  position: absolute;
  display: flex;
  height: 60px;
  width: calc(100% - 200px);
  z-index: 100;
  margin: 0 100px;
  bottom: 60px;
}

.components_footer_content_left__3DCmU {
  flex: 1 1;
  display: flex;
}

.components_footer_content_center__8Kq0- {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.components_footer_content_right__2yMSF {
  flex: 1 1;
}

.components_footer_heading__-VzZU {
  color: #2c2e3f;
  font-size: 3rem;
  font-weight: 400;
  font-family: "zooja-pro";
}

.components_footer_text__bWaT5 {
  color: #2c2e3f;
  font-size: 1.5rem;
  font-weight: 500;
}

.components_buttons__2HkPM {
  margin-top: 10px;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  /* Medium Devices, Desktops */
  .components_navbar__3eS16 {
    width: calc(100% - 20vw);
    padding: 5vh 10vw;
  }

  .components_footer__3fCJE {
    height: 20vh;
  }

  .components_footer_content__Gnz0x {
    bottom: 60px;
  }

  .components_menu__21-6z {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */
  .components_links__2un9O {
    display: none;
  }

  .components_link__3llFs {
    margin: 5px 20px;
  }

  .components_footer__3fCJE {
    height: 35vh !important;
  }

  .components_footer_content__Gnz0x {
    display: block;
    height: unset;
    width: calc(100% - 100px);
    margin: 0 50px;
    bottom: 40px !important;
  }

  .components_buttons__2HkPM {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .components_footer__3fCJE {
    height: 40vh !important;
  }

  .components_logo__2juTe {
    height: 45px;
    width: 36px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .components_logo_image__kv4IR {
    position: absolute;
    z-index: 1;
    margin-top: 6px;
    height: 36px;
    object-fit: contain;
  }

  .components_logo_name__1UZTH {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: -0.85rem;
    z-index: 2;
  }

  .components_logo_name_dark__3r2gg {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: -0.85rem;
    z-index: 2;
  }
}

@media only screen and (max-width: 360px) {
  /* Extra Small Devices, Phones */
  .components_footer__3fCJE {
    height: 45vh !important;
  }
}

@media only screen and (max-width: 320px) {
  /* Extra Small Devices, Phones */
  .components_footer__3fCJE {
    height: 50vh !important;
  }
}

.skills_categories__3-TEt {
  margin: 50px 20px 0 -100px;
  width: 80px;
  display: flex;
  flex-direction: column;
}

.skills_category_tag__236MC {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  text-align: center;
  white-space: nowrap;
}

.skills_skills_display__3P-TJ {
  display: flex;
  flex: 1 1;
  width: 105%;
  flex-flow: row wrap;
  margin-bottom: 20px;
  /*  
  inset neumorphism styling
  border-radius: 20px;
  background: #f7f2ee;
  box-shadow: inset 6px 6px 12px #dedad6, inset -6px -6px 12px #ffffff; 
  */
}

.skills_subheading__1WYUZ {
  font-weight: 500;
  font-size: 1.25em;
  color: #666666;
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* Extra Small Devices, Phones */

  .skills_skills_display__3P-TJ {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* Extra Small Devices, Phones */

  .skills_skills_display__3P-TJ {
    margin-right: 30px;
  }
}

.experience_container__3yRCl {
  width: 100%;
  padding: 30px 30px 0px 30px;
  margin-left: -30px;
  margin-bottom: 100px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #d9e2eb, -10px -10px 20px #f9fcfd;
}

.experience_container_dark__3pqIs {
  width: 100%;
  padding: 30px 30px 0px 30px;
  margin-left: -30px;
  margin-bottom: 100px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #191e2b, -10px -10px 20px #353c50;
}

.experience_experience_subcontainer__2tkIK {
  width: 100%;
}

.experience_title__2s6WC {
  font-size: 1.85em;
  font-weight: 800;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
  color: #2c2e3f;
}

.experience_skills_display__JAfCK {
  display: flex;
  flex: 1 1;
  width: 105%;
  flex-flow: row wrap;
  margin-bottom: 20px;
}

.experience_subheading__2u_7s {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #666666;
}

.experience_additional_notes__3Il02 {
  font-size: 20px;
  font-weight: 500;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  color: #666666;
}

.experience_description__2r_rb {
  margin: 0.5rem 0;
  font-size: 1.15rem;
}

ul {
  color: #000;
  margin-left: -1rem;
}

.experience_skills__2b5Xj {
  display: flex;
}

.experience_skills_type__woojd {
  flex: 1 1;
}

.experience_logos__2CwtI {
  display: flex;
  flex-flow: row wrap;
  padding-right: 40px;
}

.experience_action__2xaWt {
  display: block;
  margin-bottom: 20px;
}

.experience_action__2xaWt a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2c2e3f;
  text-decoration: none;
  vertical-align: top;

  position: relative;
}

.experience_action__2xaWt a:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #2c2e3f;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.experience_action__2xaWt a:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.experience_action__2xaWt a:hover,
.experience_action__2xaWt a:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.experience_action__2xaWt img {
  height: 24px;
  margin-right: 7px;
  object-fit: contain;
}

.experience_action_dark__3rz3p {
  display: block;
  margin-bottom: 20px;
}

.experience_action_dark__3rz3p a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #e7f0fa;
  text-decoration: none;
  vertical-align: top;

  position: relative;
}

.experience_action_dark__3rz3p a:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #e7f0fa;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.experience_action_dark__3rz3p a:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.experience_action_dark__3rz3p a:hover,
.experience_action_dark__3rz3p a:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.experience_action_dark__3rz3p img {
  height: 24px;
  margin-right: 7px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */

  .experience_container__3yRCl {
    padding: 30px 0;
    margin-left: 0;
    box-shadow: none;
  }

  .experience_container_dark__3pqIs {
    padding: 30px 0;
    margin-left: 0;
    box-shadow: none;
  }

  .experience_skills__2b5Xj {
    display: block;
  }
}

.projects_projects_subcontainer__tXrzw {
  width: 100%;
}

.projects_projects_image__2NXyw {
  width: 100%;
  border-radius: 15px;
  object-fit: contain;
  margin-bottom: 20px;
}

.projects_skills_display__10cJW {
  display: flex;
  flex: 1 1;
  width: 105%;
  flex-flow: row wrap;

  /*  
  inset neumorphism styling
  border-radius: 20px;
  background: #f7f2ee;
  box-shadow: inset 6px 6px 12px #dedad6, inset -6px -6px 12px #ffffff; 
  */
}

.projects_subheading__2uwp4 {
  font-weight: 500;
  font-size: 1.25em;
  color: #000;
  width: 100%;
  margin-bottom: 1rem;
}

.projects_description__2gzfD {
  font-size: 1.1rem;
  font-weight: 500;
  padding-right: 0px;
}

.projects_contributions__1R3OI {
  color: #2c2e3f;
  font-size: 0.95rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  /* Extra Small Devices, Phones */

  .projects_skills_display__10cJW {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* Extra Small Devices, Phones */

  .projects_skills_display__10cJW {
    margin-right: 30px;
  }
}

@media (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .projects_skills_display__10cJW {
    width: 100%;
  }

  .projects_subheading__2uwp4 {
    margin-bottom: 0.5rem;
  }
}

.contact_form_container__pyKTH {
  width: calc(100% - 20px);
  padding: 50px 40px;
  margin-left: -30px;
  margin-bottom: 75px;
  border-radius: 20px;
  background: #e7f0fa;

  box-shadow: 10px 10px 20px #d9e2eb, -10px -10px 20px #f9fcfd;
}

.contact_form_container_dark__P8Tq6 {
  width: calc(100% - 20px);
  padding: 50px 40px;
  margin-left: -30px;
  margin-bottom: 75px;
  border-radius: 20px;
  background: #2c2e3f;

  box-shadow: 10px 10px 20px #191e2b, -10px -10px 20px #353c50;
}

.contact_form_row__2DRz5 {
  width: 100%;
  display: flex;
  margin-left: 15px;
}

.contact_form_error_row__QN8V6 {
  width: 100%;
  display: flex;
  margin-left: 30px;
  margin-bottom: 25px;
}

.contact_form_error__3BhwM {
  flex: 1 1;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 500;
  color: #9d4345;
}

.contact_form_input__6R8Wa {
  flex: 1 1;
  height: 55px;
  margin-right: 30px;
  padding: 0 20px;

  font-family: "halcom", sans-serif;
  font-size: 1em;
  color: #000;

  border: none;
  border-radius: 10px;
  background: #e7f0fa;
  -webkit-appearance: none;
  box-shadow: inset 6px 6px 7px #d9e2eb, inset -6px -6px 7px #f9fcfd;
}

textarea.contact_form_input__6R8Wa {
  height: 150px !important;
  resize: none;
  padding: 15px 20px;
}

.contact_form_input__6R8Wa::-webkit-input-placeholder {
  outline: none;
  color: #666666;
}

.contact_form_input__6R8Wa::placeholder {
  outline: none;
  color: #666666;
}

.contact_form_input__6R8Wa:focus {
  outline: none;
  background: #e7f0fa;
  -webkit-appearance: none;
  box-shadow: inset 2px 2px 4px #d9e2eb, inset -2px -2px 4px #f9fcfd;
}

.contact_form_input__6R8Wa:focus::-webkit-input-placeholder {
  color: #000;
}

.contact_form_input__6R8Wa:focus::placeholder {
  color: #000;
}

.contact_form_input_dark__3Gtmj {
  flex: 1 1;
  height: 55px;
  margin-right: 30px;
  padding: 0 20px;

  font-family: "halcom", sans-serif;
  font-size: 1em;
  color: #000;

  border: none;
  border-radius: 10px;
  background: #2c2e3f;
  -webkit-appearance: none;
  box-shadow: inset 6px 6px 7px #191e2b, inset -6px -6px 7px #353c50;
}

textarea.contact_form_input_dark__3Gtmj {
  height: 150px !important;
  resize: none;
  padding: 15px 20px;
}

.contact_form_input_dark__3Gtmj::-webkit-input-placeholder {
  outline: none;
  color: #666666;
}

.contact_form_input_dark__3Gtmj::placeholder {
  outline: none;
  color: #666666;
}

.contact_form_input_dark__3Gtmj:focus {
  outline: none;
  background: #2c2e3f;
  -webkit-appearance: none;
  box-shadow: inset 2px 2px 4px #191e2b, inset -2px -2px 4px #353c50;
}

.contact_form_input_dark__3Gtmj:focus::-webkit-input-placeholder {
  color: #fff;
}

.contact_form_input_dark__3Gtmj:focus::placeholder {
  color: #fff;
}

.contact_form_button_row__1Kzkq {
  width: 100%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.contact_submit_btn__6w7Sz {
  height: 50px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #fff;

  border: none;
  border-radius: 50px;
  background: #2d2f44;
  box-shadow: 6px 6px 7px #d9e2eb, -6px -6px 7px #f9fcfd;
}

.contact_submit_btn_dark__2kN6g {
  height: 50px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #000;

  border: none;
  border-radius: 50px;
  background: #e7f0fa;
  box-shadow: 6px 6px 7px #191e2b, -6px -6px 7px #353c50;
}

.contact_submit_btn_loading__3LecU {
  height: 30px;
  margin-top: 3px;
  object-fit: contain;
}

.contact_toast_success__1cugX {
  font-family: "halcom", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #fff;

  padding-left: 15px;

  border-radius: 10px;
  background: #9d4345;
  box-shadow: 6px 6px 7px #dedad6, -6px -6px 7px #ffffff;
}

.contact_toast_success__1cugX button {
  color: #fff;
  opacity: 1;
  padding-top: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */

  .contact_form_container__pyKTH {
    width: calc(100% - 40px);
    padding: 30px 20px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  .contact_form_container_dark__P8Tq6 {
    width: calc(100% - 40px);
    padding: 30px 20px;
    margin-bottom: 50px;
    margin-left: 0px;
  }

  .contact_form_row__2DRz5 {
    display: flex;
  }
}

@media only screen and (max-width: 576px) {
  /* Extra Small Devices, Phones */

  .contact_form_container__pyKTH {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 50px;
    box-shadow: none;
  }

  .contact_form_container_dark__P8Tq6 {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 50px;
    box-shadow: none;
  }

  .contact_form_row__2DRz5 {
    width: 100%;
    display: flex;
    margin-left: 0;
  }

  .contact_form_input__6R8Wa {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-right: 0;
  }

  .contact_form_input_dark__3Gtmj {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-right: 0;
  }
}

