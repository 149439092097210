.container {
  width: 100%;
  padding: 30px 30px 0px 30px;
  margin-left: -30px;
  margin-bottom: 100px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #d9e2eb, -10px -10px 20px #f9fcfd;
}

.container_dark {
  width: 100%;
  padding: 30px 30px 0px 30px;
  margin-left: -30px;
  margin-bottom: 100px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #191e2b, -10px -10px 20px #353c50;
}

.experience_subcontainer {
  width: 100%;
}

.title {
  font-size: 1.85em;
  font-weight: 800;
  margin-block-start: 0px;
  margin-block-end: 0.25em;
  color: #2c2e3f;
}

.skills_display {
  display: flex;
  flex: 1;
  width: 105%;
  flex-flow: row wrap;
  margin-bottom: 20px;
}

.subheading {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #666666;
}

.additional_notes {
  font-size: 20px;
  font-weight: 500;
  margin-block-start: 1em;
  color: #666666;
}

.description {
  margin: 0.5rem 0;
  font-size: 1.15rem;
}

ul {
  color: #000;
  margin-left: -1rem;
}

.skills {
  display: flex;
}

.skills_type {
  flex: 1;
}

.logos {
  display: flex;
  flex-flow: row wrap;
  padding-right: 40px;
}

.action {
  display: block;
  margin-bottom: 20px;
}

.action a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2c2e3f;
  text-decoration: none;
  vertical-align: top;

  position: relative;
}

.action a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #2c2e3f;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.action a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.action a:hover,
.action a:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.action img {
  height: 24px;
  margin-right: 7px;
  object-fit: contain;
}

.action_dark {
  display: block;
  margin-bottom: 20px;
}

.action_dark a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #e7f0fa;
  text-decoration: none;
  vertical-align: top;

  position: relative;
}

.action_dark a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #e7f0fa;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.action_dark a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.action_dark a:hover,
.action_dark a:active {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.action_dark img {
  height: 24px;
  margin-right: 7px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  /* Extra Small Devices, Phones */

  .container {
    padding: 30px 0;
    margin-left: 0;
    box-shadow: none;
  }

  .container_dark {
    padding: 30px 0;
    margin-left: 0;
    box-shadow: none;
  }

  .skills {
    display: block;
  }
}
